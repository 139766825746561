
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    gender: {
      type: String,
      default: "male",
    },
    username: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
  },
  mounted() {},
  methods: {
    onClick() {
      if (!this.slug) return;
      this.$showProfile(this.slug);
    },
  },
};
