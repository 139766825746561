
export default {
  components: {
    questionAvatar: () => import('@/components/ask-question/questionAvatar.vue')
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    showMore: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 1
    }
  },
  data: () => {
    return {
      active_report: false,
      report_item: null,
      report_id: null,
      report_questions: [
        {
          text: 'Gambar',
          id: 1
        },
        {
          text: 'Spam',
          id: 2
        },
        {
          text: 'Offensive',
          id: 3
        },
        {
          text: 'Abuse',
          id: 4
        },
        {
          text: 'SARA',
          id: 5
        }
      ],
      report_others_answer: ''
    }
  },
  methods: {
    loadMore () {
      this.$emit('more')
    },
    handleDropdown (val, item) {
      switch (val) {
        case 'report' :
          this.reportQuestion(item)
          break
      }
    },
    reportQuestion (val) {
      this.report_id = val.id
      this.active_report = true
    },
    async sendReport () {
      if (!this.$store.state.auth.user.isLogin) {
        this.$goTo('login')
      } else {
        const formData = new FormData()
        formData.append('reported_id', this.report_id)
        formData.append('reason_id', this.report_item)
        await this.$store.dispatch('public/postReportQuestion', formData)
          .then((res) => {
            if (res.status) {
              this.$notify.success({
                message: 'Berhasil melaporkan pertanyaan',
                offset: 100,
                duration: 3500
              })
            } else {
              this.$notify.error({
                title: 'Gagal melaporkan pertanyaan',
                offset: 100,
                message: 'Terjadi kesalahan server, silahkan coba beberapa saat lagi'
              })
            }
            this.active_report = false
          })
          .catch(() => {
            this.active_report = false
            this.$notify.error({
              title: 'Gagal melaporkan pertanyaan',
              offset: 100,
              message: 'Terjadi kesalahan server, silahkan coba beberapa saat lagi'
            })
          })
      }
    },
    async submitLike (item) {
      if (!this.$store.state.auth.user.isLogin) {
        this.$goTo('login')
      } else {
        const payload = {
          id: item.id,
          module: 'question',
          status: 0
        }
        await this.$store.dispatch('public/postLikeQuestion', payload)
          .then((res) => {
            if (res.status) {
              if (res.data && res.data.likes_count) {
                this.list.map((obj, index) => {
                  if (obj.id === item.id) {
                    this.list[index].total_like = res.data.likes_count
                  }
                })
              }
              this.$notify.success({
                message: 'Berhasil menambah like',
                offset: 100,
                duration: 3500
              })
            } else {
              this.$notify.error({
                title: 'Gagal menambah like',
                offset: 100,
                message: 'Terjadi kesalahan server, silahkan coba beberapa saat lagi'
              })
            }
          })
          .catch(() => {
            this.$notify.error({
              title: 'Gagal menambah like',
              offset: 100,
              message: 'Terjadi kesalahan server, silahkan coba beberapa saat lagi'
            })
          })
      }
    },
    handleShare (val, item) {
      if (!window) { return }
      const base = window.location.host
      const dateNow = new Date().getTime()
      const username = this.$store.state.auth.user.myProfile.slug ? this.$store.state.auth.user.myProfile.slug : '_' + Math.floor((Math.random() * 10000) + 1)
      const url = `https://${base}/mb/${item.slug}?slug=${username}&_utm=${dateNow}`
      switch (val) {
        case 'fb' : return window.open('http://www.facebook.com/sharer/sharer.php?u=' + url + '&_ch=facebook', '_blank')
        case 'wa' : return window.open('https://web.whatsapp.com/send?text=' + url + '&_ch=whatsapp', '_blank')
        case 'tw' : return window.open('https://twitter.com/share?url=' + url + '&_ch=twitter', '_blank')
        case 'in' : return window.open('http://www.linkedin.com/shareArticle?mini=true&url=' + url + '&_ch=linkedin', '_blank')
      }
    }
  }
}
