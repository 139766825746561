
export default {
  props: {
    lists: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    goTo (slug) {
      this.$router.push(`/${slug}`)
    }
  }
}
